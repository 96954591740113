import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Box,
  Typography,
  Paper,
  TextareaAutosize,
  Divider,
  Hidden,
  Stack,
  Card,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useMediaQuery } from '@mui/material';

import weight from '../assets/Images/weihtage.png';
import shwra from '../assets/Images/shwraimage.svg';
import shwralogo from '../assets/Images/shwralogi.svg';
import ShwraImageMObile from '../assets/Images/Email Header.png';
import { useLocation, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { baseURL } from 'utils/axios';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function ConsultationForm() {
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const location = useLocation();
  const theme = useTheme();
  const searchParams = new URLSearchParams(location.search);

  console.log(searchParams);

  const lawyerId: any = searchParams.get('lawyerId');
  const appointmentId: any = searchParams.get('appointmentId');

  const [data, setData] = useState<any>(null);
  const [summary, setSummary] = useState('');
  const [keyPoints, setKeyPoints] = useState('');
  const [loading, setLoading] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [fontSize, setFontSize] = useState(20);
  const [lineHeight, setLineHeight] = useState(40);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let response = await axios.post(
        `${baseURL}Appointments/GetAppointmentDetailsByLawyerId?AppointmentId=${appointmentId}&LawyerId=${lawyerId}`
      );

      if (response?.data?.status === false) {
        setIsError(true);
      }
      if (response?.data?.result) {
        setData(response?.data?.result);
        setKeyPoints(response?.data?.result?.keyPoints);
        setSummary(response?.data?.result?.summaryOfConsultation);
      }
      setLoading(false);
      setIsApiCalled(true);
    };
    fetchData();
  }, [appointmentId, lawyerId]);

  const submitData = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    // Construct HTML for Summary and Key Points with embedded styles
    const summaryHTML = `<div style="font-size: ${fontSize}px; line-height: ${lineHeight}px;">${summary}</div>`;
    const keyPointsHTML = `<div style="font-size: ${fontSize}px; line-height: ${lineHeight}px;">${keyPoints}</div>`;

    // Send the HTML formatted text to the backend
    let response = await axios.post(`${baseURL}Appointments/AppointmentWrittenConsultation`, {
      SummaryOfConsultation: summaryHTML,
      KeyPoints: keyPointsHTML,
      AppointmentId: appointmentId,
      LoggedInUserId: lawyerId
    });

    setLoading(false);

    if (response?.data?.status === false) {
      alert('An error occurred, please try again');
    }

    if (response?.data?.status === true) {
      setSubmitSuccess(true);
    }
  };

  // if((data===null && isApiCalled) || (isError && isApiCalled))
  // {
  //   return (<Stack justifyContent={'center'} alignItems={'center'} height={'100vh'}>
  //              <Typography variant="h2" color={theme.palette.primary.main}>404 | Page Not Found</Typography>

  //      </Stack>)
  // }
  if (loading) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <CircularProgress />
      </Stack>
    );
  }
  if (data?.isFilled === true || submitSuccess) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} height={'100vh'}>
        <Card
          sx={{
            width: {
              xs: '80%',
              md: '50%',
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }
          }}
        >
          <CheckCircleIcon
            sx={{
              marginY: 4,
              width: 100,
              height: 100
            }}
            color="primary"
          />
          <Typography variant="h2" color={theme.palette.primary.main}>
            Form Submitted
          </Typography>
          <Typography variant="h3" color={theme.palette.success.dark}>
            Successfully
          </Typography>
        </Card>
      </Stack>
    );
  }
  return (
    <Grid container dir="ltr" spacing={6}>
      <Grid item lg={4} md={5} xs={12} display="flex" justifyContent="center">
        <Hidden mdDown>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              src={shwra}
              alt="shwra"
              style={{ objectFit: 'contain', filter: 'grayscale(100%)' }}
            />
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}
            >
              <img src={shwralogo} alt="shwraLogo" />
            </Box>
          </Box>
        </Hidden>
        {isSmallScreen && (
          <Box>
            <img src={ShwraImageMObile} alt="shwra" style={{ objectFit: 'contain' }} />
          </Box>
        )}
      </Grid>

      <Grid item lg={8} md={7} xs={12} dir="rtl" sx={{ my: { md: 10, sm: 4 } }}>
        <Paper elevation={3} sx={{ p: 3, borderRadius: 2, margin: 'auto', width: '90%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Box display="flex" alignItems="center" gap={2}>
              <img src={weight} alt="" width={50} height={30} />
              <Typography variant="h6" color="primary">
                استشارة قانونية
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{
                border: 1,
                borderColor: 'primary.main',
                borderRadius: '22px',
                px: 2,
                py: 0.5
              }}
            >
              <svg
                width="14"
                height="11"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.25 8.12738L1.12249 4.99988L0.0574951 6.05738L4.25 10.2499L13.25 1.24988L12.1925 0.192383L4.25 8.12738Z"
                  fill="#039855"
                />
              </svg>
              <Typography variant="body2" color="primary">
                مكتملة
              </Typography>
            </Box>
          </Box>

          <Box mb={3} px={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1" fontWeight="medium">
                الرقم المرجعي
              </Typography>
              <Typography variant="body1">{data?.referenceNumber}</Typography>
            </Box>
          </Box>
          <Divider />

          <Box my={3} px={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="body1" fontWeight="medium">
                تاريخ الموعد
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.7486 2H17.7207V0H15.6648V2H5.38551V0H3.32965V2H2.30172C1.17099 2 0.24585 2.9 0.24585 4V20C0.24585 21.1 1.17099 22 2.30172 22H18.7486C19.8794 22 20.8045 21.1 20.8045 20V4C20.8045 2.9 19.8794 2 18.7486 2ZM18.7486 20H2.30172V9H18.7486V20ZM18.7486 7H2.30172V4H18.7486V7Z"
                    fill="#DDB669"
                  />
                </svg>
                <Typography variant="body1">
                  {moment(data?.appointmentDate).locale('en').format(`DD-MM-YYYY `)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider />

          <Box my={3} px={2}>
            <Typography variant="h6" gutterBottom>
              تفاصيل الاستشارة:
            </Typography>
            <Box sx={{ p: 2, border: 1, borderColor: 'grey.300', borderRadius: 2 }}>
              <Typography variant="body2">{data?.details || '-'}</Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="center" py={4}>
            <Divider sx={{ flex: 1, backgroundColor: 'primary.main' }} />
            <Typography variant="h6" sx={{ mx: 2 }}>
              اضف تفاصيل الصورة
            </Typography>
            <Divider sx={{ flex: 1, backgroundColor: 'primary.main' }} />
          </Box>

          <Box mb={3} px={2}>
            <Typography variant="h6" gutterBottom>
              ملخص موضوع الاستشارة :
            </Typography>
            <TextareaAutosize
              onChange={(e) => setSummary(e.target.value)}
              value={summary}
              minRows={5}
              placeholder="اكتب ملخص الاستشارة ولا تقل عن  30 حرف."
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '8px',
                borderColor: 'gray',
                fontSize: '14px',
              }}
            />
          </Box>

          <Box mb={3} px={2}>
            <Typography variant="h6" gutterBottom>
              النقاط الاساسية :
            </Typography>
            <TextareaAutosize
              onChange={(e) => setKeyPoints(e.target.value)}
              value={keyPoints}
              minRows={5}
              placeholder="اكتب النقاط الاساسية للاستشارة."
              style={{
                width: '100%',
                padding: '8px',
                borderRadius: '8px',
                borderColor: 'gray',
                fontSize: '14px',
              }}
            />
          </Box>

          <Typography variant="body2" align="center" sx={{ py: 3 }}>
            قبل الظغط على ارسال رجاءا تاكد من المعلومات المكتوبة
          </Typography>

          <Box display="flex" justifyContent="center">
            <Button
              onClick={submitData}
              variant="contained"
              color="primary"
              sx={{
                width: '60%'
              }}
            >
              ارســال
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ConsultationForm;
