import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector, RootState } from '../../../redux/store';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  CircularProgress
} from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormHeader from '../FormHeader';
import { createB2bCompany, editB2b, resetError } from 'redux/slices/b2b';
import { B2bCompanies } from '../../../@types/b2b';
import useLocales from 'hooks/useLocales';

type B2bCompanyFormProps = {
  isEdit: boolean;
  b2bCompany?: null | B2bCompanies;
  setDisplay: Function;
};

export default function B2bCompanyForm({ isEdit, b2bCompany, setDisplay }: B2bCompanyFormProps) {
  const { translate } = useLocales();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = isEdit
    ? Yup.object().shape({
        shortName: Yup.string().required('الأسم المختصر مطلوب'),
        fullName: Yup.string().required('الأسم الكامل مطلوب'),
        contactEmail: Yup.string().required('البريد الالكترونى مطلوب').email(),
        description: Yup.string().required('الوصف مطلوب'),
        crNumber: Yup.string().required('الرثم الضريبي مطلوب'),
        contactNo: Yup.string().required(' رقم التواصل مطلوب'),
        address: Yup.string().required('العنوان مطلوب'),
        lattitude: Yup.number().required('العنوان مطلوب'),
        longitude: Yup.number().required('العنوان مطلوب'),
        isActive: Yup.boolean()
      })
    : Yup.object().shape({
        shortName: Yup.string().required('الأسم المختصر مطلوب'),
        fullName: Yup.string().required('الأسم الكامل مطلوب'),
        contactEmail: Yup.string().required('البريد الالكترونى مطلوب').email(),
        description: Yup.string().required('الوصف مطلوب'),
        crNumber: Yup.string().required('الرثم الضريبي مطلوب'),
        contactNo: Yup.string().required(' رقم التواصل مطلوب'),
        address: Yup.string().required('العنوان مطلوب'),
        lattitude: Yup.number().required('العنوان مطلوب'),
        longitude: Yup.number().required('العنوان مطلوب'),
        isActive: Yup.boolean()
      });
  const dispatch = useDispatch();
  const { error, isLoading, isB2bCompanyCreated, updateB2b } = useSelector(
    (state: RootState) => state.b2b
  );

  useEffect(() => {
    // @ts-ignore
    if (error?.errors) {
      // @ts-ignore
      Object.values(error.errors)
        .flat()
        .map((item: any) => enqueueSnackbar(item, { variant: 'error' }));
      dispatch(resetError());
    }
  }, [enqueueSnackbar, error, dispatch]);

  useEffect(() => {
    if (isEdit) {
      if (updateB2b?.status === 'success') {
        enqueueSnackbar('تم تعديل الشركة بنجاح', {
          variant: 'success'
        });
        navigate(PATH_DASHBOARD.b2b.root);
      }
    } else {
      if (isB2bCompanyCreated) {
        enqueueSnackbar('تم اضافة الشركة بنجاح', {
          variant: 'success'
        });
        navigate(PATH_DASHBOARD.b2b.root);
      }
    }
  }, [enqueueSnackbar, isB2bCompanyCreated, isEdit, navigate, updateB2b]);

  const formik = useFormik({
    initialValues: {
      shortName: isEdit && b2bCompany ? b2bCompany.shortName : '',
      description: isEdit && b2bCompany ? b2bCompany.description : '',
      fullName: isEdit && b2bCompany ? b2bCompany.fullName : '',
      crNumber: isEdit && b2bCompany ? b2bCompany.crNumber : '',
      contactNo: isEdit && b2bCompany ? b2bCompany.contactNo : '',
      contactEmail: isEdit && b2bCompany ? b2bCompany.contactEmail : '',
      address: isEdit && b2bCompany ? b2bCompany.address : '',
      lattitude: isEdit && b2bCompany ? b2bCompany.lattitude : 0,
      longitude: isEdit && b2bCompany ? b2bCompany.longitude : 0,
      isActive: isEdit && b2bCompany ? b2bCompany.isActive : true,
      companyUser: [] 
    },
    validationSchema: NewUserSchema,
    validateOnMount: true,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        if (isEdit && b2bCompany) {
          dispatch(editB2b({ ...values, id: b2bCompany.id }));
        } else {
          dispatch(createB2bCompany({ ...values, companyUser: [] })); // Ensure it's sent in the payload
        }
        setSubmitting(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } =
    formik;

  const theme = useTheme();
  const useStyles = makeStyles({
    root: {
      '& fieldset': {
        background:
          formik.isValid &&
          (theme.palette.mode === 'dark'
            ? `${theme.palette.error.darker} !important`
            : `${theme.palette.error.lighter} !important`)
      }
    }
  });
  const classes = useStyles();

  return (
    <>
      {isEdit && !isSubmitting && isLoading ? (
        <Stack sx={{ justifyContent: 'center', alignItems: 'center', pt: '12px' }}>
          <CircularProgress color="secondary" />
        </Stack>
      ) : (
        <FormikProvider value={formik}>
          <FormHeader
            setDisplay={setDisplay}
            Heading={!isEdit ? translate('create a new company') : 'تعديل شركة'}
          />

          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {/* <Card sx={{ p: 3 }}> */}
                <Stack spacing={3}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('short name')}
                      </Typography>
                      <TextField
                        variant="outlined"
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('shortName')}
                        error={Boolean(touched.shortName && errors.shortName)}
                        helperText={touched.shortName && errors.shortName}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('full name')}
                      </Typography>
                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('fullName')}
                        error={Boolean(touched.fullName && errors.fullName)}
                        helperText={touched.fullName && errors.fullName}
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('description')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('tax number')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        label=""
                        {...getFieldProps('crNumber')}
                        error={Boolean(touched.crNumber && errors.crNumber)}
                        helperText={touched.crNumber && errors.crNumber}
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('contact number')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('contactNo')}
                        error={Boolean(touched.contactNo && errors.contactNo)}
                        helperText={touched.contactNo && errors.contactNo}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>{translate('email')}</Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        label=""
                        {...getFieldProps('contactEmail')}
                        error={Boolean(touched.contactEmail && errors.contactEmail)}
                        helperText={touched.contactEmail && errors.contactEmail}
                        autoComplete="mail"
                      />
                    </Box>
                  </Stack>

                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('address')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Box>
                  </Stack>
                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('latitudes')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        {...getFieldProps('lattitude')}
                        error={Boolean(touched.lattitude && errors.lattitude)}
                        helperText={touched.lattitude && errors.lattitude}
                      />
                    </Box>

                    <Box sx={{ minWidth: '48%' }}>
                      <Typography sx={{ color: 'text.secondary' }}>
                        {translate('location')}
                      </Typography>

                      <TextField
                        className={classes.root}
                        fullWidth
                        label=""
                        {...getFieldProps('longitude')}
                        error={Boolean(touched.longitude && errors.longitude)}
                        helperText={touched.longitude && errors.longitude}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Grid>

              {/* //---------------------- */}

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(event) => {
                        // setDisplay(false)
                        setFieldValue('isActive', event.target.checked);
                      }}
                      checked={formik.values.isActive}
                    />
                  }
                  label={translate('availability')}
                />
              </Grid>

              <Grid item xs={12}>
                <LoadingButton
                  disabled={!formik.isValid}
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {!isEdit ? translate('create') : 'تعديل'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}
