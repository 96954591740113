import * as Sentry from '@sentry/react';
import { findIndex, find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// service
import { accountService } from '../../services';
// @types
import { ClientState } from '../../@types/client';
import { phoneNumber } from 'utils/mock-data/phoneNumber';

// ----------------------------------------------------------------------

const initialState: ClientState = {
  isLoading: false,
  error: false,

  clientList: {
    totalCount: 0,
    data: [],
    Page: 0,
    Size: 0
  },

  clientRequestList: [],

  statusList: [],

  client: null,

  isOpenModal: false,
  clientRegister: '',
  blockClientStatus: {}
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENT LIST
    getClientListSuccess(state, action) {
      state.isLoading = false;
      state.clientList = action.payload;
    },

    // GET CLIENT BY ID
    getClientSuccess(state, action) {
      state.isLoading = false;
      state.client = action.payload;
    },

    // CREATE CLIENT
    createClientSuccess(state, action) {
      state.isLoading = false;

      state.clientList = {
        ...state.clientList,
        data: [...state.clientList.data, action.payload]
      };
    },

    // UPDATE CLIENT
    updateClientSuccess(state, action) {
      state.isLoading = false;
    },

    // DELETE CLIENT
    deleteClientSuccess(state, action) {
      state.isLoading = false;
    },

    // MANAGE CLIENT
    manageClientSuccess(state, action) {
      state.isLoading = false;

      const status: any = find(
        state.statusList,
        (actionRecord: any) => actionRecord.id === Number(action.payload.statusId)
      );

      const clientIndex: any = findIndex(
        state.clientList.data,
        (actionRecord: any) => actionRecord.clientId === String(action.payload.clientId)
      );

      state.clientList.data[clientIndex].status = status.name;
    },

    // STATUS CLIENT
    clientStatusListSuccess(state, action) {
      state.isLoading = false;
      state.statusList = action.payload;
    },

    //----------------------------------------
    // GET CLIENT REQUEST LIST
    getClientRequestListSuccess(state, action) {
      state.isLoading = false;
      state.clientRequestList = action.payload;
    },
    //----------------------------------------
    registerClientSuccess(state, action) {
      state.isLoading = false;
      state.isOpenModal = false;
      state.clientRegister = action.payload;
    },
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

    blockClientStatus(state, action) {
      state.isLoading = false;
      state.blockClientStatus = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

//-------------------------------------------------------------------------------------

export function getClientList({ Page, Size, OrderBy, Order, PhoneNumber, IsApproved, name }: { Page: number; Size: number, OrderBy: string | null, Order: string | null, PhoneNumber: string | null, IsApproved: boolean | null | string, name: string | null }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      console.log(name, "name")
      const response = await axios.get('Clients/GetAllClients', {
        params: {
          Page,
          Size,
          OrderBy: OrderBy ? OrderBy : "CreatedDate",
          Order: Order,
          PhoneNumber: PhoneNumber ? PhoneNumber : null,
          IsApproved: IsApproved,
          name: name ? name : null
        }
      });
      dispatch(slice.actions.getClientListSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function blockClient(clientId: any, isBlock: boolean) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/Clients/blockUnblockClient`,
        {
          data: {
            "clientId":clientId,
            "isBlock":isBlock
          }
        });
      if (response?.data?.status === false) {

        dispatch(slice.actions.blockClientStatus({ status: 'error', error: response?.data }));
      }
      else
        dispatch(slice.actions.blockClientStatus({ status: 'success', ...response?.data?.result }));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.blockClientStatus({ status: 'error', error: error }));
    }
  };
}

//----------------------------------------------------------------------------

export function getClient({ clientId }: { clientId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Clients/GetClientById?ClientId=${clientId}`);

      dispatch(slice.actions.getClientSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createClient({
  firstName,
  lastName,
  email,
  password
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const createdClient = await axios.post('/Account/client/register', {
        firstName,
        lastName,
        userName: email,
        email,
        password,
        confirmPassword: password
      });

      if (createdClient?.data?.status === false) {
        dispatch(slice.actions.hasError(createdClient?.data));

      }
      else {
        const response = await axios.get(`/Clients/${createdClient.data?.result?.userId}`);
        dispatch(slice.actions.createClientSuccess(response.data?.result));
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

//--------------------------------------------------------------------------------

export function manageClientStatus({ clientId, statusId }: { clientId: string; statusId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(
        slice.actions.manageClientSuccess({
          clientId,
          statusId
        })
      );
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClientRequests({ clientId }: { clientId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Clients/GetClientRequestsById?id=${clientId}`);
      dispatch(slice.actions.getClientRequestListSuccess(response.data?.result?.requests));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerClient(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Lawyers/register', payload);
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));

      }
      else
        dispatch(slice.actions.registerClientSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setRegisterClient(data?: any) {
  return () => {
    dispatch(slice.actions.registerClientSuccess(data));
    dispatch(slice.actions.hasError(data));
  };
}

export function setClientBlock(data: any) {
  return dispatch(slice.actions.blockClientStatus(data));
}