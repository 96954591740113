import * as Sentry from '@sentry/react';
import { findIndex, find } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// service
import { accountService } from '../../services';
// @types
import { ClientState } from '../../@types/client';
import { phoneNumber } from 'utils/mock-data/phoneNumber';
import { B2bState } from '../../@types//b2b';

// ----------------------------------------------------------------------

const initialState: B2bState = {
  isLoading: false,
  error: false,
  isB2bCompanyCreated: false,
  isB2bCompanyCustomerCreated: false,
  isB2bCompanyCustomerUpdated: false,

  b2bCompaniesList: {
    totalCount: 0,
    result: [],
    page: 0,
    pageSize: 0,
  },
  b2bCompanyCustomers: {
    items: [],
    pageNumber: 0,
    pageSize: 0,
    totalCount: 0,
  },

  clientRequestList: [],

  statusList: [],

  b2bCompany: null,

  isOpenModal: false,
  clientRegister: '',
  blockClientStatus: {},
  updateB2b: {},
  updateB2bCustomer: {},
  isB2bDeleted: false
};

const slice = createSlice({
  name: 'b2b',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setCreated(state) {
      state.isB2bCompanyCreated = true;
    },

    setCreatedCustomer(state) {
      state.isB2bCompanyCustomerCreated = true;
    },

    setUpdatedCustomer(state) {
      state.isB2bCompanyCustomerUpdated = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENT LIST
    getB2bCompaniesListSuccess(state, action) {
      state.isLoading = false;
      state.b2bCompaniesList = action.payload;
    },

    // GET CLIENT BY ID
    getB2bCompanySuccess(state, action) {
      state.isLoading = false;
      state.b2bCompany = action.payload;
    },
    getB2bCompanyCustomersSuccess(state, action) {
      state.isLoading = false;
      state.b2bCompanyCustomers = action.payload;
    },

    // CREATE CLIENT
    createB2bCompanySuccess(state, action) {
      state.isLoading = false;
      state.isB2bCompanyCreated = true;
      state.b2bCompaniesList = {
        ...state.b2bCompaniesList,
        result: [...state.b2bCompaniesList.result, action.payload]
      };
    },

    createB2bCompanyCustomerSuccess(state, action) {
      state.isLoading = false;
      state.isB2bCompanyCustomerCreated = true;
      // state.b2bCompanyCustomers = {
      //   ...state.b2bCompanyCustomers,
      //   result: [...state.b2bCompanyCustomers.result, action.payload]
      // };
    },

    updateB2bCustomerSuccess(state, action) {
      state.isLoading = false;
      state.isB2bCompanyCustomerUpdated = true
      // state.updateB2bCustomer = action.payload;
    },

    // UPDATE CLIENT


    updateB2bSuccess(state, action) {
      state.isLoading = false;
      // state.isB2bCompanyCreated=true
      state.updateB2b = action.payload;
    },

    //Delete 

    deleteB2bSuccess(state, action) {
      state.isB2bDeleted = true;
      state.isLoading = false;
    },

    // STATUS CLIENT
    clientStatusListSuccess(state, action) {
      state.isLoading = false;
      state.statusList = action.payload;
    },

    //----------------------------------------
    // GET CLIENT REQUEST LIST
    getClientRequestListSuccess(state, action) {
      state.isLoading = false;
      state.clientRequestList = action.payload;
    },
    //----------------------------------------
    registerClientSuccess(state, action) {
      state.isLoading = false;
      state.isOpenModal = false;
      state.clientRegister = action.payload;
    },
    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
    },

    blockClientStatus(state, action) {
      state.isLoading = false;
      state.blockClientStatus = action.payload;
    },

    resetB2bState(state) {
      state.isB2bCompanyCreated = false;
      state.isB2bCompanyCustomerCreated = false;
      state.isB2bCompanyCustomerUpdated = false;
      state.updateB2b = false;
      state.isB2bDeleted = false;
      state.updateB2b = "";

    },
    resetHasError(state) {
      state.isLoading = false;
      state.error = false;
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal } = slice.actions;

//-------------------------------------------------------------------------------------

export function getB2bCompaniesList({ page, limit, orderBy, sort, SearchKeyword }: { page: number; limit: number, orderBy: string | null, sort: string | null, SearchKeyword: string | null }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('Company/GetAllCompaniesAsync', {
        params: {
          page,
          limit,
          orderBy: orderBy ? orderBy : "CreatedDate",
          sort: sort,
          SearchKeyword: SearchKeyword ? SearchKeyword : null,
        }
      });
      dispatch(slice.actions.getB2bCompaniesListSuccess(response.data));
      console.log(response, "response::response")
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


//----------------------------------------------------------------------------

export function getB2bCompany(b2bCompanyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Company/GetCompanyByIdAsync?Id=${b2bCompanyId}`);

      dispatch(slice.actions.getB2bCompanySuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getB2bCompanyCustomers(b2bCompanyId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Company/GetCustomerByCompanyIdAsync?Id=${b2bCompanyId}`);
      dispatch(slice.actions.getB2bCompanyCustomersSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createB2bCompany({
  shortName,
  description,
  fullName,
  crNumber,
  contactNo,
  contactEmail,
  address,
  lattitude,
  longitude,
  isActive,
  companyUser
}: {
  shortName: string;
  description: string;
  fullName: string;
  crNumber: string;
  contactNo: string;
  contactEmail: string;
  address: string;
  lattitude: number;
  longitude: number;
  isActive: boolean;
  companyUser: []
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Company/CreateCompanyAsync', {
        shortName,
        description,
        fullName,
        crNumber,
        contactNo,
        contactEmail,
        address,
        lattitude,
        longitude,
        isActive,
        companyUser
      });

      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));

      }
      else {
        dispatch(slice.actions.setCreated());
        const createdB2bCompany = await axios.get(`/Company/GetCompanyByIdAsync?Id=${response.data?.result}`);
        dispatch(slice.actions.createB2bCompanySuccess(createdB2bCompany.data?.result));
        dispatch(slice.actions.resetB2bState());

      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createB2bCompanyCustomer(params: any, companyId?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/AppAdmin/AdminCustomerCreate', params);

      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));
      }
      else {
        // const createdLawyer = await axios.get(`/Lawyers/${response.data?.result}`);
        // dispatch(slice.actions.resetB2bState());
        dispatch(slice.actions.setCreatedCustomer());
        const createdB2bCompany = await axios.get(`/Company/GetCompanyByIdAsync?Id=${companyId}`);
        dispatch(slice.actions.createB2bCompanyCustomerSuccess(createdB2bCompany?.data?.result));
        dispatch(slice.actions.resetB2bState());

      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateB2bCompanyCustomer(params: any, companyId?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/AppAdmin/AdminCustomerUpdate`, params);
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));

      }
      else {
        dispatch(slice.actions.setUpdatedCustomer());
        const updateB2bCustomer = await axios.get(`/Company/GetCompanyByIdAsync?Id=${companyId ? companyId : response.data?.result?.companyTags[0]}`);
        dispatch(slice.actions.updateB2bCustomerSuccess(updateB2bCustomer.data?.result));
        dispatch(slice.actions.resetB2bState());
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function editB2b({
  id,
  shortName,
  description,
  fullName,
  crNumber,
  contactNo,
  contactEmail,
  address,
  lattitude,
  longitude,
  isActive,
}: {
  id: string;
  shortName: string;
  description: string;
  fullName: string;
  crNumber: string;
  contactNo: string;
  contactEmail: string;
  address: string;
  lattitude: number;
  longitude: number;
  isActive: boolean;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // const response = await axios.put(`/Lawyers/UpdateLawyerByAdmin/${lawyerId}`, params);
      const response = await axios.put('/Company/UpdateCompanyAsync', {
        id,
        shortName,
        description,
        fullName,
        crNumber,
        contactNo,
        contactEmail,
        address,
        lattitude,
        longitude,
        isActive,
      });
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));

      }
      else {
        dispatch(slice.actions.updateB2bSuccess({ status: 'success', ...response?.data?.result }));
        dispatch(slice.actions.resetB2bState());

      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.updateB2bSuccess({ status: 'error', error: error }));

    }
  };
}

export function deleteB2b({ b2bId }: { b2bId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/Company/DeleteCompanyAsync?Id=${b2bId}`);
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));
      }
      else {
        dispatch(slice.actions.deleteB2bSuccess(b2bId));
        dispatch(slice.actions.resetB2bState());
      }
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClientRequests({ clientId }: { clientId: string }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/Clients/GetClientRequestsById?id=${clientId}`);
      dispatch(slice.actions.getClientRequestListSuccess(response.data?.result?.requests));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function registerClient(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/Lawyers/register', payload);
      if (response?.data?.status === false) {
        dispatch(slice.actions.hasError(response?.data));

      }
      else
        dispatch(slice.actions.registerClientSuccess(response.data?.result));
    } catch (error) {
      Sentry.captureException(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setRegisterClient(data?: any) {
  return () => {
    dispatch(slice.actions.registerClientSuccess(data));
    dispatch(slice.actions.hasError(data));
  };
}

export function setClientBlock(data: any) {
  return dispatch(slice.actions.blockClientStatus(data));
}

export function reset() {
  return dispatch(slice.actions.resetB2bState());
}

export function resetError() {
  return dispatch(slice.actions.resetHasError());
}